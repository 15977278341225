import Vue from 'vue'
import VueRouter from 'vue-router'
const Login = () => import(/* webpackChunkName: "Login" */'../views/Login.vue')
const ResetPassword = () => import(/* webpackChunkName: "resetPassword" */'../views/ResetPassword.vue')
const ForgotPassword = () => import(/* webpackChunkName: "forgotPassword" */'../views/ForgotPassword.vue')
const Dashboard = () => import(/* webpackChunkName: "dashboard" */'../views/Dashboard.vue')
const PartnerProfile = () => import(/* webpackChunkName: "forgotPassword" */'../views/PartnerProfile.vue')
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/forgot_pass',
    name: 'Forgot Password',
    component: ForgotPassword
  },
  {
    path: '/reset_pass',
    name: 'Reset Password',
    component: ResetPassword
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/partner_profile',
    name: 'Partner Profile',
    component: PartnerProfile
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
