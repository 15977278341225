import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import 'vue-awesome/icons'
import Icon from 'vue-awesome/components/Icon'
import './plugins/bootstrap-vue'
import App from './App.vue'
import store from './store'
import router from './router'
import vSelect from 'vue-select'
import makeToast from './mixins/makeToast'
import 'vue-select/dist/vue-select.css';


Vue.component('v-select', vSelect)
Vue.component('v-icon', Icon)

Vue.mixin(makeToast)
Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
