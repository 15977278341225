
export default {
    methods: {
        makeToast({ variant = null, message, title = "Notice" }) {
            this.$bvToast.toast(message, {
              variant: variant,
              title,
              solid: true,
            });
          },
    }
};