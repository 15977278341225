<template>
  <div id="app">
    <!-- Todo remove not-supported case -->
    <router-view v-if="this.size > 760"/>
     <not-supported v-else />
  </div>
</template>
<script>
import NotSupported from '@/components/Organisams/NotSupported.vue'
export default {
    name: 'PortalApp',
    data()
      {
      return {
        size : window.innerWidth
      }
    },
    components:{NotSupported},
    methods: {
      viewPortWidthCalculation (e) {
        this.size = e.target.innerWidth
      }
    },
    mounted(){
      window.addEventListener('resize', this.viewPortWidthCalculation)
    },
    beforeDestroy(){
      window.removeEventListener('resize',this.viewPortWidthCalculation)
    },
}
</script>
<style lang="scss">
@import "~@/assets/scss/vendors/bootstrap-vue/index";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.head-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 2%;
}

.head-buttons {
  display: flex;
  flex-direction: row;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.whiteIcon path {
  fill: white;
}

</style>
