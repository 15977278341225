<template>
    <div class="notSupported">
        <span>Our application currently only supports Desktop Devices.</span>
        <!-- <img src="../../assets/uclogo_white.png"/> -->
    </div>
</template>
<script>
export default {
    name: 'NotSupported'
}
</script>
<style lang="scss" scoped>
    .notSupported {
        width: 100vw;
        height: 100vh;
        color: white;
        background: #002240;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        padding: 20px;
        flex-direction: column;
        font-size: 22px;
       img {
           max-width: 100%;
           width: 150px;
           margin-top: 30px;
        }
        span {
        width: 300px;

        }
    }
</style>